import React from 'react';

import { AnimatedOnScroll } from "react-animated-css-onscroll";

class Technologies extends React.Component {

    render() {
        return (
            <div className="container technologies section" id="technologies">
                <p className="sub-title">Some of the tools I work with</p>
                <h2>Technology Stack</h2>
                <div className="seperator-title"></div>
                <div className="row">
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">Javascript</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">Typescript</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">Python</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">Java</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">C#</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">Swift</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">HTML5</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">CSS</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">Bootstrap</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip hide-mobile-chip">
                            <p className="technologies-chip-text">Material Design</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">Wordpress</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip hide-mobile-chip">
                            <p className="technologies-chip-text">D3.js</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">React</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">React Native</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">Angular</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">.NET</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">Redux</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip hide-mobile-chip">
                            <p className="technologies-chip-text">Node.js</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip hide-mobile-chip">
                            <p className="technologies-chip-text">Express</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip hide-mobile-chip">
                            <p className="technologies-chip-text">REST</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">SQL</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">noSQL</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">MongoDB</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip hide-mobile-chip">
                            <p className="technologies-chip-text">Realm</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">Firebase</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">AWS</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">GitHub</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip hide-mobile-chip">
                            <p className="technologies-chip-text">Machine Learning</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">Adobe Illustrator</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">Adobe Photoshop</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip">
                            <p className="technologies-chip-text">Adobe XD</p>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="zoomIn">
                        <div className="technologies-chip hide-mobile-chip">
                            <p className="technologies-chip-text">Microsoft Office</p>
                        </div>
                    </AnimatedOnScroll>

                </div>
            </div >
        );
    }
}

export default Technologies;