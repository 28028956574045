// REACT
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import NavBar from "../Presentation/HomeScreen/NavBar";
import Header from "../Presentation/HomeScreen/Header";
import Expertise from "../Presentation/HomeScreen/Expertise";
import Technologies from "../Presentation/HomeScreen/Technologies";
import Personal from "../Presentation/HomeScreen/Personal";
import Portfolio from "../Presentation/HomeScreen/Portfolio";
import Footer from "../Presentation/HomeScreen/Footer";


class HomeScreen extends Component {

    render() {
        return (
            <div className="main" id="main">
                <NavBar />
                <Header />
                <Expertise />
                <Technologies />
                <Portfolio />
                <Personal />
                <Footer />
            </div>
        )
    }
}


export default withRouter(HomeScreen);