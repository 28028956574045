import React from "react";
import { AnimatedOnScroll } from "react-animated-css-onscroll";

class Expertise extends React.Component {
    render() {
        return (
            <div className="container expertise section" id="expertise">
                <p className="sub-title">The main areas that I work within</p>
                <h2>My Professional Expertise</h2>
                <div className="seperator-title"></div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 expertise-col">
                        <AnimatedOnScroll animationIn="zoomIn">
                            <div className="expertise-circle">
                                <i className="fas fa-code expertise-icon"></i>
                            </div>
                            <h4>Full Stack Development</h4>
                            <p className="expertise-text">Full-stack development from database management to client frontend. Currently using React as my framework of choice.</p>
                        </AnimatedOnScroll>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 expertise-col">
                        <AnimatedOnScroll animationIn="zoomIn">
                            <div className="expertise-circle">
                                <i className="fab fa-apple expertise-icon"></i>
                            </div>
                            <h4>App Development</h4>
                            <p className="expertise-text">Mainly IOS/Android development using React Native. Possesses knowledge in native languages (Swift/Kotlin).</p>
                        </AnimatedOnScroll>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 expertise-col">
                        <AnimatedOnScroll animationIn="zoomIn">
                            <div className="expertise-circle">
                                <i className="fas fa-paint-brush expertise-icon"></i>
                            </div>
                            <h4>UI/UX Design</h4>
                            <p className="expertise-text">Graphic work and UI/UX for web &amp; apps. Knowledge within the Adobe Suite with programs like Photoshop, Illustrator and XD.</p>
                        </AnimatedOnScroll>
                    </div>
                </div>
            </div>
        );
    }
}

export default Expertise;
