import React from "react";

import { AnimatedOnScroll } from "react-animated-css-onscroll";

import jm from "../../../Assets/Images/JM-Logo.svg";

class Header extends React.Component {
    scrollToView = (id) => {
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
    };

    render() {
        return (
            <div className="header" id="header">
                <AnimatedOnScroll animationIn="fadeInDown">
                    <div className="header-text">
                        <img src={jm} className="jm-logo" alt="logo"></img>
                        <h1 className="name-span">
                            <span className="jacob-span">JACOB</span>
                            <span className="moller-span">MÖLLER</span>
                        </h1>

                        <div className="separator-box">
                            <div className="separator-line"></div>
                        </div>
                        <h3 className="sub-header-text">Engineer / Developer / Designer</h3>

                        <div>
                            <a className="copyright-link" href="https://www.allabolag.se/960504KNOD/moller-technology" target="_blank" rel="noopener noreferrer">
                                <p className="copyright-text">
                                    <span className="copyright">&copy; </span>Möller Technology 2021
                                </p>
                            </a>
                        </div>
                        <div className="social-row">
                            <a href="https://www.linkedin.com/in/jacob-moller/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-linkedin social-icon"></i>
                            </a>
                            <a href="https://github.com/jacobmoller8">
                                <i className="fab fa-github-square social-icon" target="_blank" rel="noopener noreferrer"></i>
                            </a>
                            <a href="https://twitter.com/jacobmoller8">
                                <i className="fab fa-twitter-square social-icon" target="_blank" rel="noopener noreferrer"></i>
                            </a>
                        </div>
                    </div>
                </AnimatedOnScroll>

                <div className="chevron-row">
                    <i className="fas fa-chevron-down chevron-icon bounce" onClick={() => this.scrollToView("expertise")}></i>
                </div>
            </div>
        );
    }
}

export default Header;
