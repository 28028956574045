import React from "react";

import Samla from "../../../Assets/Images/Projects/Samla.png";
import Fudget from "../../../Assets/Images/Projects/Fudget.png";
import ClinTri from "../../../Assets/Images/Projects/ClinTri.png";
import GreenCobra from "../../../Assets/Images/Projects/GreenCobra.png";

import Foodviz from "../../../Assets/Images/Projects/Foodviz.png";
import SHB from "../../../Assets/Images/Projects/SHB.png";
import CubeMap from "../../../Assets/Images/Projects/CubeMap.png";
import ThisOrThat from "../../../Assets/Images/Projects/ThisOrThat.png";

import { AnimatedOnScroll } from "react-animated-css-onscroll";

class Portfolio extends React.Component {
    render() {
        return (
            <div className="container portfolio section" id="portfolio">
                <p className="sub-title">Some of the projects I have worked on</p>
                <h2>Experiences Delivered</h2>
                <div className="seperator-title"></div>
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-6 left-col">
                        <AnimatedOnScroll animationIn="fadeInLeft">
                            <h5>
                                Fudget <span className="thin-text">- Fun with a budget, for everyone</span>
                            </h5>
                            <div className="image-div">
                                <img src={Fudget} alt="Fudget" className="portfolio-picture" />
                                <div className="hover-box">
                                    <p className="hover-title">Description</p>
                                    <p className="hover-text">Application development of a new app with the goal for people finding nearby restaurants at affordable pricing. The application uses the MERN stack.</p>
                                    <p className="hover-title">Technologies</p>
                                    <p className="hover-text">React Native - MongoDB - Node - Express</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-8 portfolio-lower-col-left d-flex align-items-center">
                                    <p className="portfolio-text">APPLICATION DEV</p>
                                </div>
                                <div className="col-4 portfolio-lower-col-right d-flex justify-content-end align-items-center">
                                    <a href="https://www.fudget.se" target="_blank" rel="noopener noreferrer">
                                        <div className="portfolio-button">
                                            <p className="portfolio-button-text">Visit Site</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="portfolio-separator"></div>
                        </AnimatedOnScroll>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 right-col">
                        <AnimatedOnScroll animationIn="fadeInRight">
                            <h5>
                                Samla <span className="thin-text">- Become a sustainable winner</span>
                            </h5>
                            <div className="image-div">
                                <img src={Samla} alt="Samla" className="portfolio-picture" />
                                <div className="hover-box">
                                    <p className="hover-title">Description</p>
                                    <p className="hover-text">Web and app development for a startup company I am a co-founder of. Helping companies become sustainable winners by recycling cans with the help of sport associations.</p>
                                    <p className="hover-title">Technologies</p>
                                    <p className="hover-text">React - Firebase - Node - Express</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8 portfolio-lower-col-left d-flex align-items-center">
                                    <p className="portfolio-text">WEB DEV &amp; DESIGN</p>
                                </div>
                                <div className="col-4 portfolio-lower-col-right d-flex justify-content-end align-items-center">
                                    <a href="https://www.samlasverige.com" target="_blank" rel="noopener noreferrer">
                                        <div className="portfolio-button">
                                            <p className="portfolio-button-text">Visit Site</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="portfolio-separator"></div>
                        </AnimatedOnScroll>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-6 left-col">
                            <AnimatedOnScroll animationIn="fadeInLeft">
                                <h5>
                                    This or That <span className="thin-text">- Who has the weirdest habits?</span>
                                </h5>
                                <div className="image-div">
                                    <img src={ThisOrThat} alt="Fudget" className="portfolio-picture" />
                                    <div className="hover-box">
                                        <p className="hover-title">Description</p>
                                        <p className="hover-text">Iphone game where you answer this or that questions and compare your results with other user. Download and try it out with your friends! </p>
                                        <p className="hover-title">Technologies</p>
                                        <p className="hover-text">React Native - MongoDB - Apple Dev</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-8 portfolio-lower-col-left d-flex align-items-center">
                                        <p className="portfolio-text">APPLICATION DEV</p>
                                    </div>
                                    <div className="col-4 portfolio-lower-col-right d-flex justify-content-end align-items-center">
                                        <a href="https://apps.apple.com/se/app/this-or-that/id1523911304" target="_blank" rel="noopener noreferrer">
                                            <div className="portfolio-button">
                                                <p className="portfolio-button-text">Download</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="portfolio-separator"></div>
                            </AnimatedOnScroll>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 right-col">
                            <AnimatedOnScroll animationIn="fadeInRight">
                                <h5>
                                    FoodViz <span className="thin-text">- Visualize food climate footprint </span>
                                </h5>
                                <div className="image-div">
                                    <img src={Foodviz} alt="Samla" className="portfolio-picture" />
                                    <div className="hover-box">
                                        <p className="hover-title">Description</p>
                                        <p className="hover-text">Master thesis at Deedster. Visualizing climate footprint from food receipt data to help users get a better understanding of their footprint.</p>
                                        <p className="hover-title">Technologies</p>
                                        <p className="hover-text">React - API's - D3.js - GitHub</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8 portfolio-lower-col-left d-flex align-items-center">
                                        <p className="portfolio-text">WEB DEV &amp; DESIGN</p>
                                    </div>
                                    <div className="col-4 portfolio-lower-col-right d-flex justify-content-end align-items-center">
                                        <a href="https://jacobmoller8.github.io/exjobb/" target="_blank" rel="noopener noreferrer">
                                            <div className="portfolio-button">
                                                <p className="portfolio-button-text">Visit Site</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="portfolio-separator"></div>
                            </AnimatedOnScroll>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-6 left-col">
                            <AnimatedOnScroll animationIn="fadeInLeft">
                                <h5>
                                    ClinTri <span className="thin-text">- Clinical trials information visualization</span>
                                </h5>
                                <div className="image-div">
                                    <img src={ClinTri} alt="ClinTri" className="portfolio-picture" />
                                    <div className="hover-box">
                                        <p className="hover-title">Description</p>
                                        <p className="hover-text">School project with the aim to visualize Clinical Trials for researchers Using the ClinicalTrials.gov API.</p>
                                        <p className="hover-title">Technologies</p>
                                        <p className="hover-text">D3 - GitHub - JavaScript</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8 portfolio-lower-col-left d-flex align-items-center">
                                        <p className="portfolio-text">D3 VISUALIZATION</p>
                                    </div>
                                    <div className="col-4 portfolio-lower-col-right d-flex justify-content-end align-items-center">
                                        <a href="https://hugobergqvist.github.io/clintri" target="_blank" rel="noopener noreferrer">
                                            <div className="portfolio-button">
                                                <p className="portfolio-button-text">Visit Site</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="portfolio-separator"></div>
                            </AnimatedOnScroll>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 right-col">
                            <AnimatedOnScroll animationIn="fadeInRight">
                                <h5>
                                    CubeMap <span className="thin-text">- WebGL graphics project</span>
                                </h5>
                                <div className="image-div">
                                    <img src={CubeMap} alt="Samla" className="portfolio-picture" />
                                    <div className="hover-box">
                                        <p className="hover-title">Description</p>
                                        <p className="hover-text">Graphics project at KTH. Utilizing the WebGL library to create environments with shaders, skybox, camera movements and interactions. </p>
                                        <p className="hover-title">Technologies</p>
                                        <p className="hover-text">WebGL - OpenGL- JavaScript</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8 portfolio-lower-col-left d-flex align-items-center">
                                        <p className="portfolio-text">COMPUTER GRAPHICS</p>
                                    </div>
                                    <div className="col-4 portfolio-lower-col-right d-flex justify-content-end align-items-center">
                                        <a href="https://hugobergqvist.github.io/cubemap/" target="_blank" rel="noopener noreferrer">
                                            <div className="portfolio-button">
                                                <p className="portfolio-button-text">Visit Site</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="portfolio-separator"></div>
                            </AnimatedOnScroll>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-6 left-col">
                            <AnimatedOnScroll animationIn="fadeInLeft">
                                <h5>
                                    SHB<span className="thin-text">- Handelsbanken Capital Markets</span>
                                </h5>
                                <div className="image-div">
                                    <img src={SHB} alt="Fudget" className="portfolio-picture" />
                                    <div className="hover-box">
                                        <p className="hover-title">Description</p>
                                        <p className="hover-text">Services for creating and editing analyst reports. Developing with .NET Core Clean Architecture.</p>
                                        <p className="hover-title">Technologies</p>
                                        <p className="hover-text">Angular - C# - MSSQL - .NET</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-8 portfolio-lower-col-left d-flex align-items-center">
                                        <p className="portfolio-text">WEB DEV</p>
                                    </div>
                                    <div className="col-4 portfolio-lower-col-right d-flex justify-content-end align-items-center">
                                        <a href="https://www.researchonline.se/start" target="_blank" rel="noopener noreferrer">
                                            <div className="portfolio-button">
                                                <p className="portfolio-button-text">Visit Site</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="portfolio-separator"></div>
                            </AnimatedOnScroll>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 right-col">
                            <AnimatedOnScroll animationIn="fadeInRight">
                                <h5>
                                    GreenCobra <span className="thin-text">- Food inventory system</span>
                                </h5>
                                <div className="image-div">
                                    <img src={GreenCobra} alt="GreenCobra" className="portfolio-picture" />
                                    <div className="hover-box">
                                        <p className="hover-title">Description</p>
                                        <p className="hover-text">Candidate thesis project and consultant work for KTH, creating a web base Food Inventory System for households.</p>
                                        <p className="hover-title">Technologies</p>
                                        <p className="hover-text">React - Firebase - GitHub</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8 portfolio-lower-col-left d-flex align-items-center">
                                        <p className="portfolio-text">WEB DEV &amp; DESIGN</p>
                                    </div>
                                    <div className="col-4 portfolio-lower-col-right d-flex justify-content-end align-items-center">
                                        <a href="https://greencobra.web.app/" target="_blank" rel="noopener noreferrer">
                                            <div className="portfolio-button">
                                                <p className="portfolio-button-text">Visit Site</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="portfolio-separator"></div>
                            </AnimatedOnScroll>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Portfolio;
