import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import "./Assets/Styles/index.css"

import HomeScreen from "./Components/Container/HomeScreen";
import ThisOrThatPolicyScreen from "./Components/Container/ThisOrThatPolicyScreen";

class App extends Component {

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <Route exact path="/" render={() => <HomeScreen />} />
          <Route exact path="/thisorthat/policy" render={() => <ThisOrThatPolicyScreen />} />
        </header>
      </div>
    );
  }
}

export default App;
