import React from "react";

class Footer extends React.Component {
    render() {
        return (
            <div className="footer" id="footer">
                <div className="container">
                    <p className="footer-text">If you would like to contact me for any business inquiries or anything else, feel free to drop me an email:</p>
                    <a href="mailto:jm@jacobmoller.se">
                        <p className="footer-mail-text">jm@jacobmoller.se</p>
                    </a>
                    <p>
                        <i className="fas fa-map-marker-alt location-icon"></i> Stockholm, Sweden
                    </p>
                    <div className="social-row">
                        <a href="https://www.linkedin.com/in/jacob-moller/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-linkedin social-icon"></i>
                        </a>
                        <a href="https://github.com/jacobmoller8" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-github-square social-icon"></i>
                        </a>
                        <a href="https://twitter.com/jacobmoller8" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-twitter-square social-icon"></i>
                        </a>
                    </div>
                    <a className="copyright-link" href="https://www.allabolag.se/960504KNOD/moller-technology" target="_blank" rel="noopener noreferrer">
                        <p className="copyright-text-footer">
                            <span className="copyright">&copy; </span>2021 Möller Technology, All Rights Reserved.
                        </p>
                    </a>
                </div>
            </div>
        );
    }
}

export default Footer;
