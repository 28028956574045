import React from "react";

import profile from "../../../Assets/Images/profile-picture.jpg";

class Personal extends React.Component {
    render() {
        return (
            <div className="container personal section" id="personal">
                <p className="sub-title">A pleasure to meet you</p>
                <h2>Hi there, I'm Jacob</h2>
                <div className="seperator-title"></div>
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-6 personal-col">
                        <p className="personal-text">I am a software engineer from Stockholm, Sweden. My educational background is from KTH Royal Institute of Technnlogy with a M.Sc in Interactive Media Technnlogy.</p>
                        <p className="personal-text">My passion is developing &amp; designing delightful experiences with the combination of business, marketing and UX/UI design mindset.</p>
                        <p className="personal-text">
                            Currently I am working within the Aviation industry with prior experience in Telecom and banking In 2020 I co-founded a startup called Samla which I still have an active commitment and CTO role in.
                        </p>
                        <p className="personal-text">Apart from work my interests are golfing, traveling and skiing, aswell as spending quality time with friends and family.</p>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 personal-col d-flex justify-content-end">
                        <img src={profile} className="profile-picture" alt="profile" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Personal;
