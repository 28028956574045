import React from 'react';

import Logo from "../../../Assets/Images/JM-Logo.svg";

class NavBar extends React.Component {

    scrollToView = (id) => {
        const yOffset = -80;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    render() {
        return (
            <div>
                <nav className="navbar" id="navbar">
                    <a className="navbar-logo" href="/">
                        <img className="navbar-logo" id="navbar-logo" src={Logo} alt="logo" />
                    </a>

                    <div className="navbar-button" onClick={() => this.scrollToView("footer")}>
                        <p className="navbar-button-text" >Contact me</p>
                    </div>
                </nav>


            </div>
        );
    }
}

export default NavBar;